import { Box, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AvailableIconButton } from "../../components/buttons/AvailableIconButton";
import { DeleteIconButton } from "../../components/buttons/DeleteIconButton";
import { EditIconButton } from "../../components/buttons/EditIconButton";
import { JokerIconButton } from "../../components/buttons/JokerIconButton";
import { AvailableChip } from "../../components/chips/AvailableChip";
import { JokerChip } from "../../components/chips/JokerChip";
import { DeleteDialog } from "../../components/dialogs/DeleteDialog";
import { Label } from "../../types/Label.type";
import { IdProps } from "../../types/Props.type";
import { Club } from "../clubs/Club";
import { selectTournamentRound } from "../tournaments/tournaments.feature";
import { UpdatePlayerDialog } from "./UpdatePlayerDialog";
import {
  removePlayer,
  selectPlayerById,
  updatePlayer,
} from "./players.feature";

export const PlayersTableRow = ({ id }: IdProps) => {
  const dispatch = useAppDispatch();
  const player = useAppSelector((state) => selectPlayerById(state, id));
  const round = useAppSelector(selectTournamentRound);
  const [showIcons, setShowIcons] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const onChangePlayerAvailability = () => {
    player &&
      dispatch(
        updatePlayer({
          ...player,
          available: !player.available,
        }),
      );
  };

  const onChangePlayerJoker = () => {
    player &&
      dispatch(
        updatePlayer({
          ...player,
          joker: player.joker > 0 ? 0 : (round ?? 0),
        }),
      );
  };

  return (
    <>
      {player ? (
        <TableRow
          key={id}
          sx={{
            "& td": { lineHeight: 2 },
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          onMouseEnter={() => setShowIcons(true)}
          onMouseLeave={() => setShowIcons(false)}
          hover
        >
          <TableCell>
            <AvailableChip available={player.available} />
            {player.joker === 0 && <JokerChip />}
          </TableCell>
          <TableCell>
            {player.prename} {player.surname}
          </TableCell>
          <TableCell>{player.club && <Club id={player.club} />}</TableCell>
          <TableCell>{player.group}</TableCell>
          <TableCell>{player.handycap}</TableCell>
          <TableCell>
            {showIcons && (
              <Box display="inline">
                <AvailableIconButton
                  available={player.available}
                  onClick={onChangePlayerAvailability}
                />
                <JokerIconButton
                  joker={player.joker}
                  onClick={onChangePlayerJoker}
                />
                <EditIconButton
                  onClick={setOpenUpdateDialog}
                  title="Update player"
                />
                {openUpdateDialog && (
                  <UpdatePlayerDialog
                    player={player}
                    openDialog={openUpdateDialog}
                    setOpenDialog={setOpenUpdateDialog}
                  />
                )}
                <DeleteIconButton
                  onClick={setOpenDeleteDialog}
                  title="Delete player"
                />
                {openDeleteDialog && (
                  <DeleteDialog
                    id={player.id}
                    openDialog={openDeleteDialog}
                    setOpenDialog={setOpenDeleteDialog}
                    action={removePlayer}
                    label={Label.Player}
                  />
                )}
              </Box>
            )}
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};
