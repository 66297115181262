import { MouseEvent, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { fetchClubs, fetchPlayers } from "../features/api/api.feature";
import type { AppDispatch, RootState } from "./store";
import { getTournaments } from "../features/tournaments/tournaments.feature";
import { getPlayers } from "../features/players/players.feature";
import { getGames } from "../features/games/games.feature";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type ThunkApi = { state: RootState };

export const useDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  return { anchorEl, open, handleOnClick, handleOnClose };
};

export const useStepper = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const skipNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
  };

  const handlePrevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const resetStepper = () => {
    setActiveStep(0);
  };

  return {
    activeStep,
    skipNextStep,
    handleNextStep,
    handlePrevStep,
    resetStepper,
  };
};

export const useTournamentStore = () => {
  const dispatch = useAppDispatch();
  dispatch(getTournaments())
    .then(() => {
      dispatch(getPlayers());
      dispatch(getGames());
    })
    .finally(() => {
      console.log("Successfully fetched players and games for tournament");
    });
};

export const useApiStore = () => {
  const dispatch = useAppDispatch();
  dispatch(fetchClubs());
  dispatch(fetchPlayers());
};
