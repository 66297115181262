import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  Dispatch,
  FormEvent,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector, useStepper } from "../../app/hooks";
import { NumberInputSelect } from "../../components/inputs/NumberInputSelect";
import { TextInput } from "../../components/inputs/TextInput";
import { TextInputSelect } from "../../components/inputs/TextInputSelect";
import { Player } from "../../types/Player.type";
import { PlayerAutocomplete } from "./PlayerAutocomplete";
import {
  addPlayer,
  groups,
  handycaps,
  selectIfPlayerExists,
} from "./players.feature";
import { InputGroup } from "../../components/inputs/InputGroup";
import { PrevButton } from "../../components/buttons/PrevButton";
import { NextButton } from "../../components/buttons/NextButton";
import { ResetButton } from "../../components/buttons/ResetButton";

type AddPlayerDialogProps = {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

export const AddPlayerDialog = ({
  openDialog,
  setOpenDialog,
}: AddPlayerDialogProps) => {
  const dispatch = useAppDispatch();
  const [prename, setPrename] = useState("");
  const [surname, setSurname] = useState("");
  const [club, setClub] = useState("");
  const [group, setGroup] = useState("");
  const [handycap, setHandycap] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const {
    activeStep,
    skipNextStep,
    handleNextStep,
    handlePrevStep,
    resetStepper,
  } = useStepper();

  useEffect(() => {
    setDisabled(prename.length === 0 || surname.length === 0);
  }, [prename, surname]);

  const clubs = useAppSelector((state) => state.api.clubs);

  const playerExists = useAppSelector((state) =>
    selectIfPlayerExists(state, prename + surname),
  );

  const onAutocompleteChange = (e: any, value: any) => {
    if (value) {
      setPrename(value.prename);
      setSurname(value.surname);
      setClub(value.club);
      skipNextStep();
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setPrename("");
    setSurname("");
    setClub("");
    setGroup("");
    setHandycap(0);
    setDisabled(true);
  };

  const handleConfirm = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(addPlayer({ prename, surname, club, group, handycap } as Player));
    setOpenDialog(false);
  };

  const handleReset = () => {
    resetForm();
    resetStepper();
  };

  const steps = [
    {
      label: "Select player",
      optional: true,
      element: <PlayerAutocomplete onChange={onAutocompleteChange} />,
    },
    {
      label: "Add name",
      optional: false,
      element: (
        <InputGroup>
          <TextInput label="Prename" value={prename} setValue={setPrename} />
          <TextInput label="Surname" value={surname} setValue={setSurname} />
        </InputGroup>
      ),
    },
    {
      label: "Add details",
      optional: true,
      element: (
        <InputGroup>
          <TextInputSelect
            label="Club"
            value={club ?? null}
            setValue={setClub}
            values={clubs}
          />
          <TextInputSelect
            label="Group"
            value={group ?? null}
            setValue={setGroup}
            values={groups}
          />
          <NumberInputSelect
            label="Handycap"
            value={handycap}
            setValue={setHandycap}
            values={handycaps}
          />
        </InputGroup>
      ),
    },
    {
      label: "Confirm",
      optional: false,
      element: (
        <Typography>
          Add player{" "}
          <b>
            {prename} {surname}
          </b>
          {group && ` to Group ${group}`}
        </Typography>
      ),
    },
  ];

  const isFinalStep = () => activeStep === steps.length - 1;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle>Add player</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select player from the list or add player by name
        </DialogContentText>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step) => {
            const labelProps: {
              optional?: ReactNode;
              exists?: ReactNode;
            } = {};
            if (step.optional) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (playerExists) {
              labelProps.exists = (
                <Typography variant="caption">Player already exists</Typography>
              );
            }
            return (
              <Step key={step.label}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
                <StepContent>
                  {step.element}
                  <Box m={1}>
                    {playerExists && (
                      <Alert severity="error" sx={{ m: 1 }}>
                        Player already exists
                      </Alert>
                    )}
                    <ResetButton
                      disabled={activeStep === 0}
                      onClick={handleReset}
                    />
                    <PrevButton
                      disabled={activeStep === 0}
                      onClick={handlePrevStep}
                    />
                    <NextButton
                      disabled={(disabled && !step.optional) || playerExists}
                      onClick={isFinalStep() ? handleConfirm : handleNextStep}
                    />
                  </Box>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
};
