import { Route, Routes } from "react-router-dom";
import { Games } from "../features/games/Games";
import { Players } from "../features/players/Players";
import { Standings } from "../features/standings/Standings";
import { Tournaments } from "../features/tournaments/Tournaments";

export const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Tournaments />} />
      <Route path="/players" element={<Players />} />
      <Route path="/games" element={<Games />} />
      <Route path="/standings" element={<Standings />} />
      <Route path="/tournaments" element={<Tournaments />} />
    </Routes>
  );
};
