import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LogoutIcon from "@mui/icons-material/Logout";
import MonitorIcon from "@mui/icons-material/Monitor";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import {
  Divider,
  Drawer,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { signOut, User } from "firebase/auth";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "@nikolausturnier/shared/src/firebase";
import { URL } from "@nikolausturnier/shared/src/config";
import { useAppSelector } from "../app/hooks";
import { selectTournamentName } from "../features/tournaments/tournaments.feature";

type SidebarProps = {
  user: User;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const Sidebar = ({ user, open, setOpen }: SidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tournament = useAppSelector(selectTournamentName);

  const handleOnItemClick = useCallback(
    (path: string) => {
      setOpen(false);
      navigate(path);
    },
    [navigate, setOpen],
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.altKey) {
        switch (e.key) {
          case "p":
            handleOnItemClick("/players");
            break;
          case "g":
            handleOnItemClick("/games");
            break;
          case "s":
            handleOnItemClick("/standings");
            break;
          case "t":
            handleOnItemClick("/tournaments");
            break;
          case "m":
            setOpen(!open);
            break;
        }
      }
    },
    [handleOnItemClick, open, setOpen],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleOnLogoutClick = async () => {
    setOpen(false);
    await signOut(auth);
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <MenuList sx={{ minWidth: { sm: 320 } }}>
        <ListSubheader>{tournament}</ListSubheader>
        <MenuItem
          onClick={() => handleOnItemClick("/players")}
          selected={location.pathname === "/players"}
        >
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText>Players</ListItemText>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Alt + P
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleOnItemClick("/games")}
          selected={location.pathname === "/games"}
        >
          <ListItemIcon>
            <ScoreboardIcon />
          </ListItemIcon>
          <ListItemText>Games</ListItemText>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Alt + G
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleOnItemClick("/standings")}
          selected={location.pathname === "/standings"}
        >
          <ListItemIcon>
            <FormatListNumberedIcon />
          </ListItemIcon>
          <ListItemText>Standings</ListItemText>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Alt + S
          </Typography>
        </MenuItem>
        <MenuItem component="a" href={URL.display} target="_blank">
          <ListItemIcon>
            <MonitorIcon />
          </ListItemIcon>
          <ListItemText>Display</ListItemText>
        </MenuItem>
        <Divider />
        <ListSubheader>{user?.displayName}</ListSubheader>
        <MenuItem
          onClick={() => handleOnItemClick("/tournaments")}
          selected={location.pathname === "/tournaments"}
        >
          <ListItemIcon>
            <EmojiEventsIcon />
          </ListItemIcon>
          <ListItemText>Tournaments</ListItemText>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Alt + T
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleOnLogoutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
        <Divider />
        <ListSubheader>Menu</ListSubheader>
        <MenuItem onClick={() => setOpen(true)}>
          <ListItemIcon sx={{ minWidth: 36 }}>
            <KeyboardArrowLeftIcon />
          </ListItemIcon>
          <ListItemText>Open</ListItemText>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Alt + M
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => setOpen(false)}>
          <ListItemIcon sx={{ minWidth: 36 }}>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
          <ListItemText>Close</ListItemText>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Alt + M
          </Typography>
        </MenuItem>
      </MenuList>
    </Drawer>
  );
};
