import CasinoIcon from "@mui/icons-material/Casino";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AddButton } from "../../components/buttons/AddButton";
import { Game } from "../../types/Game.type";
import { Label } from "../../types/Label.type";
import { PlayerTextField } from "../players/PlayerTextField";
import {
  drawRandomPlayerIds,
  selectAvailablePlayerIdsByRound,
  selectAvailablePlayerIdsByRoundForGroupA,
  selectAvailablePlayerIdsByRoundForGroupB,
  selectStartingScore,
} from "../players/players.feature";
import { selectTournamentRound } from "../tournaments/tournaments.feature";
import { addGame, selectIfPairExists } from "./games.feature";

type AddGameDialogProps = {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

export const AddGameDialog = ({
  openDialog,
  setOpenDialog,
}: AddGameDialogProps) => {
  const dispatch = useAppDispatch();
  const round = useAppSelector(selectTournamentRound);
  const playerIds = useAppSelector(selectAvailablePlayerIdsByRound);
  const playerIdsGroupA = useAppSelector(
    selectAvailablePlayerIdsByRoundForGroupA,
  );
  const playerIdsGroupB = useAppSelector(
    selectAvailablePlayerIdsByRoundForGroupB,
  );

  const [playerA, setPlayerA] = useState("");
  const [playerB, setPlayerB] = useState("");
  const [playerC, setPlayerC] = useState("");
  const [playerD, setPlayerD] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(!(playerA && playerB && playerC && playerD));
  }, [playerA, playerB, playerC, playerD]);

  const [homeScore, awayScore] = useAppSelector((state) =>
    selectStartingScore(state, { playerA, playerB, playerC, playerD }),
  );

  const homePairExists = useAppSelector((state) =>
    selectIfPairExists(state, [playerA, playerB]),
  );

  const awayPairExists = useAppSelector((state) =>
    selectIfPairExists(state, [playerC, playerD]),
  );

  const resetForm = () => {
    setPlayerA("");
    setPlayerB("");
    setPlayerC("");
    setPlayerD("");
    setDisabled(true);
  };

  const handleOnAddClick = (e: any) => {
    e.preventDefault();
    dispatch(
      addGame({
        home: { playerA, playerB, score: homeScore },
        away: { playerA: playerC, playerB: playerD, score: awayScore },
        finished: false,
        round,
      } as Game),
    );
    setOpenDialog(false);
    resetForm();
  };

  const handleOnRandomDrawClick = () => {
    const [playerA, playerB, playerC, playerD] = drawRandomPlayerIds(
      playerIds,
      4,
    );
    setPlayerA(playerA);
    setPlayerB(playerB);
    setPlayerC(playerC);
    setPlayerD(playerD);
  };

  const handleOnCrossGroupDrawClick = () => {
    const [playerA, playerC] = drawRandomPlayerIds(playerIdsGroupA, 2);
    const [playerB, playerD] = drawRandomPlayerIds(playerIdsGroupB, 2);
    setPlayerA(playerA);
    setPlayerB(playerB);
    setPlayerC(playerC);
    setPlayerD(playerD);
  };

  const handleOnGroupDrawClickForGroupA = () => {
    const [playerA, playerB, playerC, playerD] = drawRandomPlayerIds(
      playerIdsGroupA,
      4,
    );
    setPlayerA(playerA);
    setPlayerB(playerB);
    setPlayerC(playerC);
    setPlayerD(playerD);
  };

  const handleOnGroupDrawClickForGroupB = () => {
    const [playerA, playerB, playerC, playerD] = drawRandomPlayerIds(
      playerIdsGroupB,
      4,
    );
    setPlayerA(playerA);
    setPlayerB(playerB);
    setPlayerC(playerC);
    setPlayerD(playerD);
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle>Add game</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Draw game for <i>round {round}</i> by group or randomly
        </DialogContentText>
        <Box sx={{ mt: 2 }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs>
              <PlayerTextField
                value={playerA}
                values={playerIds}
                setValue={setPlayerA}
                ignoreValues={[playerB, playerC, playerD]}
              />
              <PlayerTextField
                value={playerB}
                values={playerIds}
                setValue={setPlayerB}
                ignoreValues={[playerA, playerC, playerD]}
              />
              {homePairExists && (
                <Alert severity="error" sx={{ m: 1 }}>
                  Pair already exists
                </Alert>
              )}
            </Grid>
            <Grid item>
              <Typography>vs.</Typography>
            </Grid>
            <Grid item xs>
              <PlayerTextField
                value={playerC}
                values={playerIds}
                setValue={setPlayerC}
                ignoreValues={[playerA, playerB, playerD]}
              />
              <PlayerTextField
                value={playerD}
                values={playerIds}
                setValue={setPlayerD}
                ignoreValues={[playerA, playerB, playerC]}
              />
              {awayPairExists && (
                <Alert severity="error" sx={{ m: 1 }}>
                  Pair already exists
                </Alert>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box display="flex" gap={1}>
              <Button
                disabled={playerIdsGroupA.length < 4}
                onClick={handleOnGroupDrawClickForGroupA}
                startIcon={<CompareArrowsIcon />}
                variant="outlined"
              >
                Group A
              </Button>
              <Button
                disabled={playerIdsGroupB.length < 4}
                onClick={handleOnGroupDrawClickForGroupB}
                startIcon={<CompareArrowsIcon />}
                variant="outlined"
              >
                Group B
              </Button>
              <Button
                disabled={
                  playerIdsGroupA.length < 2 || playerIdsGroupB.length < 2
                }
                onClick={handleOnCrossGroupDrawClick}
                startIcon={<ShuffleIcon />}
                variant="outlined"
              >
                Cross Group
              </Button>
              <Button
                disabled={playerIds.length < 4}
                onClick={handleOnRandomDrawClick}
                startIcon={<CasinoIcon />}
                variant="outlined"
              >
                Random
              </Button>
            </Box>
          </Grid>
          <Grid item>
            <AddButton
              onClick={handleOnAddClick}
              label={Label.Game}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
