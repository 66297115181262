import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { User } from "firebase/auth";
import { useAppSelector } from "../app/hooks";
import { selectTournamentName } from "../features/tournaments/tournaments.feature";
import { Navigation } from "./Navigation";
import { Logo } from "./logos/Logo";

type UserProps = {
  user?: User;
};

export const Header = ({ user }: UserProps) => {
  const name = useAppSelector(selectTournamentName);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Logo user={user} />
          <Typography
            align="center"
            variant="h4"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
            }}
          >
            {name ?? "Nikolausturnier"}
          </Typography>
          {user && <Navigation user={user} />}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
