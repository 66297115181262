import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { logEvent } from "firebase/analytics";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { analytics, auth, db } from "@nikolausturnier/shared/src/firebase";
import { RootState } from "../../app/store";
import { Label } from "../../types/Label.type";
import { Tournament } from "../../types/Tournament.type";
import {
  TournamentsFilterOptions,
  selectTournamentsFilter,
} from "../filters/filters.feature";
import { ThunkApi } from "../../app/hooks";

export const DisplayOptions = {
  Games: "Games",
  Standings: "Standings",
  Tournament: "Tournament",
};

const tournamentsAdapter = createEntityAdapter<Tournament>({});

const initialState = tournamentsAdapter.getInitialState();

export const getTournaments = createAsyncThunk<Tournament[], void, ThunkApi>(
  "tournaments/getTournaments",
  async (_, thunkApi) => {
    const uid = auth.currentUser?.uid;
    const querySnapshot = await getDocs(collection(db, `${uid}`));
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Tournament[];
  },
);

export const addTournament = createAsyncThunk(
  "tournaments/addTournament",
  async (tournament: Tournament) => {
    const uid = auth.currentUser?.uid;
    tournament = {
      ...tournament,
      display: DisplayOptions.Tournament,
      round: 1,
      active: true,
    };
    const { id } = await addDoc(collection(db, `${uid}`), tournament);
    id && logEvent(analytics, "add", { type: Label.Tournament });
    return { ...tournament, id };
  },
);

export const updateTournament = createAsyncThunk(
  "tournaments/updateTournament",
  async (tournament: Tournament) => {
    const uid = auth.currentUser?.uid;
    const changes = {
      date: tournament.date,
      display: tournament.display,
      name: tournament.name,
      round: tournament.round,
    };
    await updateDoc(doc(db, `${uid}`, tournament.id), changes).then(() =>
      logEvent(analytics, "update", { type: Label.Tournament }),
    );
    return {
      id: tournament.id,
      changes,
    };
  },
);

export const loadTournament = createAsyncThunk(
  "tournaments/loadTournament",
  async (id: string) => {
    const uid = auth.currentUser?.uid;
    const changes = {
      active: true,
    };
    await updateDoc(doc(db, `${uid}`, id), changes);
    return {
      id,
      changes,
    };
  },
);

export const unloadTournament = createAsyncThunk(
  "tournaments/unloadTournament",
  async (id: string) => {
    const uid = auth.currentUser?.uid;
    const changes = {
      active: false,
    };
    await updateDoc(doc(db, `${uid}`, id), changes);
    return {
      id,
      changes,
    };
  },
);

export const tournamentsSlice = createSlice({
  name: "tournaments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTournaments.fulfilled, (state, action) => {
        tournamentsAdapter.addMany(state, action.payload);
      })
      .addCase(addTournament.fulfilled, (state, action) => {
        tournamentsAdapter.addOne(state, action.payload);
      })
      .addCase(updateTournament.fulfilled, (state, action) => {
        tournamentsAdapter.updateOne(state, action.payload);
      })
      .addCase(loadTournament.fulfilled, (state, action) => {
        tournamentsAdapter.updateOne(state, action.payload);
      })
      .addCase(unloadTournament.fulfilled, (state, action) => {
        tournamentsAdapter.updateOne(state, action.payload);
      });
  },
});

export const {
  selectAll: selectTournaments,
  selectById: selectTournamentById,
} = tournamentsAdapter.getSelectors((state: RootState) => state.tournaments);

export const selectTournament = createSelector(
  selectTournaments,
  (tournaments) => {
    return tournaments.find((tournament) => tournament.active);
  },
);

export const selectFilteredTournamentIds = createSelector(
  selectTournaments,
  selectTournamentsFilter,
  (tournaments, filter) => {
    const today = new Date().toISOString().split("T")[0];
    if (filter === TournamentsFilterOptions.Upcoming) {
      tournaments = tournaments.filter(
        (tournament) => tournament.date >= today,
      );
    } else if (filter === TournamentsFilterOptions.Past) {
      tournaments = tournaments.filter((tournament) => tournament.date < today);
    }
    return tournaments
      .sort((a, b) => b.date.localeCompare(a.date))
      .map((tournament) => tournament.id);
  },
);

export const selectTournamentId = createSelector(
  selectTournament,
  (tournament) => tournament?.id,
);

export const selectTournamentName = createSelector(
  selectTournament,
  (tournament) => tournament?.name,
);

export const selectTournamentRound = createSelector(
  selectTournament,
  (tournament) => tournament?.round,
);

export default tournamentsSlice.reducer;
