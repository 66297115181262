import { Button } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

type ResetButtonProps = {
  disabled: boolean;
  onClick: () => void;
};

export const ResetButton = ({ disabled, onClick }: ResetButtonProps) => {
  return (
    <Button
      color="warning"
      disabled={disabled}
      onClick={onClick}
      startIcon={<RestartAltIcon />}
      sx={{ mr: 1 }}
    >
      Reset
    </Button>
  );
};
