import { Chip } from "@mui/material";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

export const ActiveChip = () => {
  return (
    <Chip
      icon={<ToggleOnIcon />}
      label="Active"
      color="primary"
      sx={{ ml: 1 }}
    />
  );
};
