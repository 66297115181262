import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { auth } from "@nikolausturnier/shared/src/firebase";
import { clubs } from "@nikolausturnier/shared/src/clubs";

export type ClubFromApi = {
  id: string;
  name: string;
};

type PlayerFromApi = {
  prename: string;
  surname: string;
  club: string | null;
};

const initialState = {
  clubs: Array<ClubFromApi>(),
  players: Array<PlayerFromApi>(),
  status: "idle",
};

const url =
  "https://api-bsc-buederich-default-rtdb.europe-west1.firebasedatabase.app";

export const fetchClubs = createAsyncThunk<Array<ClubFromApi>>(
  "api/fetchClubs",
  async () => {
    const response = await fetch(`${url}/clubs.json`);
    const json = await response.json();
    const clubs: ClubFromApi[] = Object.values(json);
    return clubs.sort((a, b) => a.name.localeCompare(b.name));
  },
);

export const fetchPlayers = createAsyncThunk<Array<PlayerFromApi>>(
  "api/fetchPlayers",
  async () => {
    let clubId = "01-0257";
    if (auth.currentUser?.email) {
      const key = auth.currentUser.email.match(/@.*/)?.at(0);
      clubId = clubs[key as keyof typeof clubs].id;
    }
    const response = await fetch(
      `${url}/players.json?orderBy="club"&equalTo="${clubId}"`,
    );
    const json = await response.json();
    const players: PlayerFromApi[] = Object.values(json);
    return players.sort((a, b) => a.prename.localeCompare(b.prename));
  },
);

export const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClubs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClubs.fulfilled, (state, action) => {
        state.clubs = action.payload;
        state.status = "idle";
      })
      .addCase(fetchPlayers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPlayers.fulfilled, (state, action) => {
        state.players = action.payload;
        state.status = "idle";
      });
  },
});

export default apiSlice.reducer;
