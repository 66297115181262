import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { logEvent } from "firebase/analytics";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { analytics, auth, db } from "@nikolausturnier/shared/src/firebase";
import { ThunkApi } from "../../app/hooks";
import { RootState } from "../../app/store";
import { Game, GameChanges } from "../../types/Game.type";
import { Label } from "../../types/Label.type";
import {
  GamesFilterOptions,
  selectGamesFilter,
} from "../filters/filters.feature";
import { selectTournamentRound } from "../tournaments/tournaments.feature";

const gamesAdapter = createEntityAdapter<Game>({});

const initialState = gamesAdapter.getInitialState({
  status: "idle",
});

export const getGames = createAsyncThunk<Game[], void, ThunkApi>(
  "games/getGames",
  async (_, thunkApi) => {
    const uid = auth.currentUser?.uid;
    const tid = Object.values(thunkApi.getState().tournaments.entities).find(
      (tournament) => tournament?.active,
    )?.id;
    const querySnapshot = await getDocs(collection(db, `${uid}/${tid}/games`));
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Game[];
  },
);

export const addGame = createAsyncThunk<Game, Game, ThunkApi>(
  "games/addGame",
  async (game: Game, thunkApi) => {
    const uid = auth.currentUser?.uid;
    const tid = Object.values(thunkApi.getState().tournaments.entities).find(
      (tournament) => tournament?.active,
    )?.id;
    const { id } = await addDoc(collection(db, `${uid}/${tid}/games`), game);
    id && logEvent(analytics, "add", { type: Label.Game });
    return { ...game, id };
  },
);

export const updateGame = createAsyncThunk<GameChanges, Game, ThunkApi>(
  "games/updateGame",
  async (game: Game, thunkApi) => {
    const uid = auth.currentUser?.uid;
    const tid = Object.values(thunkApi.getState().tournaments.entities).find(
      (tournament) => tournament?.active,
    )?.id;
    const changes = {
      home: game.home,
      away: game.away,
      finished: game.finished,
      round: game.round,
    };
    await updateDoc(doc(db, `${uid}/${tid}/games`, game.id), changes).then(() =>
      logEvent(analytics, "update", { type: Label.Game }),
    );
    return {
      id: game.id,
      changes,
    };
  },
);

export const removeGame = createAsyncThunk<string, string, ThunkApi>(
  "games/removeGame",
  async (id: string, thunkApi) => {
    const uid = auth.currentUser?.uid;
    const tid = Object.values(thunkApi.getState().tournaments.entities).find(
      (tournament) => tournament?.active,
    )?.id;
    await deleteDoc(doc(db, `${uid}/${tid}/games`, id)).then(() =>
      logEvent(analytics, "remove", { type: Label.Game }),
    );
    return id;
  },
);

export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {
    resetGames(state) {
      gamesAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGames.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getGames.fulfilled, (state, action) => {
        gamesAdapter.addMany(state, action.payload);
        state.status = "idle";
      })
      .addCase(getGames.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(addGame.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addGame.fulfilled, (state, action) => {
        gamesAdapter.addOne(state, action.payload);
        state.status = "idle";
      })
      .addCase(addGame.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(removeGame.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeGame.fulfilled, (state, action) => {
        gamesAdapter.removeOne(state, action.payload);
        state.status = "idle";
      })
      .addCase(removeGame.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateGame.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateGame.fulfilled, (state, action) => {
        gamesAdapter.updateOne(state, action.payload);
        state.status = "idle";
      })
      .addCase(updateGame.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { selectAll: selectGames, selectById: selectGameById } =
  gamesAdapter.getSelectors((state: RootState) => state.games);

export const selectFilteredGameIds = createSelector(
  selectGames,
  selectGamesFilter,
  selectTournamentRound,
  (games, filter, round) => {
    if (filter === GamesFilterOptions.Finished) {
      games = games.filter((game) => game.finished);
    } else if (filter === GamesFilterOptions.Upcoming) {
      games = games.filter((game) => !game.finished);
    } else if (filter === GamesFilterOptions.Round && round) {
      games = games.filter((game) => game.round === round);
    }
    return games.sort((a, b) => a.round - b.round).map((game) => game.id);
  },
);

const selectMaxRound = createSelector(selectGames, (games) => {
  return games.length > 0
    ? Math.max(...Object.values(games.map((game) => game.round)))
    : 0;
});

export const selectRounds = createSelector(selectMaxRound, (maxRound) => {
  return Array.from(Array(maxRound + 1).keys()).map((i) => i + 1);
});

export const selectFinishedGames = createSelector(selectGames, (games) => {
  return games.filter((game) => game.finished);
});

export const selectIfPairExists = createSelector(
  [selectGames, (state, pair) => pair],
  (games, pair) => {
    const homePairs = Object.values(
      games.map((game) => [game.home.playerA, game.home.playerB]),
    );
    for (const homePair of homePairs) {
      if (homePair.sort().join() === pair.sort().join()) {
        return true;
      }
    }

    const awayPairs = Object.values(
      games.map((game) => [game.away.playerA, game.away.playerB]),
    );
    for (const awayPair of awayPairs) {
      if (awayPair.sort().join() === pair.sort().join()) {
        return true;
      }
    }

    return false;
  },
);

export const selectHighScoreGames = createSelector(
  selectFinishedGames,
  (games) => {
    const gamesWithScores = games.map((game) => {
      const score = Math.abs(game.home.score - game.away.score);
      return {
        score,
        ...game,
      };
    });

    const highScore = Math.max(...gamesWithScores.map((game) => game.score));
    return gamesWithScores.filter((game) => game.score === highScore);
  },
);

export const { resetGames } = gamesSlice.actions;

export default gamesSlice.reducer;
